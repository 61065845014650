import React from 'react';
import ArticleComponent from '../components/Blog/ArticleComponent';
import useBreakpoint from '../utils/useBreakpoint';
import Seo from '../components/SEO';
import { navigate } from 'gatsby';

const BlogPost = ({ pageContext }) => {
  const isMobile = useBreakpoint('sm', false);
  if (!pageContext) {
    return;
  }

  const handleTagClick = (tag) => {
    if (pageContext?.lang?.split('-')[0] === 'en') {
      navigate(`/blog/${tag.toLowerCase().replace(/ /g, '-')}`);
      return;
    }
    navigate(
      `${pageContext?.lang?.split('-')[0]}/blog/${tag.toLowerCase().replace(/ /g, '-')}`
    );
  };

  const navigateToBlog = () => {
    const currentLang = pageContext?.lang?.split('-')[0];
    if (currentLang === 'en') {
      navigate('/blog');
      return;
    }
    navigate(`/${currentLang}/blog`);
  };

  return (
    <>
      <Seo
        title={pageContext.data.meta_title}
        description={pageContext.data.meta_description}
        image={pageContext?.data?.background?.url}
        lang={pageContext?.lang?.split('-')[0]}
        type="article"
      />
      <div
        style={{
          minHeight: '100vh',
          padding: 32,
          paddingTop: isMobile ? 164 : `calc(144px + 96px)`,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            backgroundColor: '#F5F5F5',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: 564,
            zIndex: -1,
          }}
        />
        <div
          style={{
            width: '100%',
            maxWidth: 870,
          }}
        >
          <div
            className="color-text-dark subtitle"
            style={{ marginBottom: 64, fontWeight: 600 }}
          >
            <span className="blog-nav-link" onClick={() => navigateToBlog()}>
              Blog
            </span>
            <span style={{ color: '#9B9B9B' }}>{' / '}</span>
            {pageContext.data.title}
          </div>
          <ArticleComponent
            background={pageContext?.data?.background?.fluid}
            title={pageContext?.data?.title}
            date={pageContext?.data?.date}
            content={pageContext?.data?.content}
            author={pageContext?.data?.author?.document?.data}
            tags={pageContext?.tags}
            onTagClick={handleTagClick}
            size="xl"
          />
        </div>
      </div>
    </>
  );
};

export default BlogPost;
